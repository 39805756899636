import "@css/main.scss";

import Alpine from "alpinejs";

import "./swup";
import "./shopify";
import "./masonry";
import "./glide";

Alpine.start();

// suggested in the Aline docs:
// make Alpine on window available for better DX
window.Alpine = Alpine;
