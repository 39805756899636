import Swup from "swup";
import SwupFadeTheme from "@swup/fade-theme";
import SwupScrollPlugin from "@swup/scroll-plugin";

const swup = new Swup({
  containers: ["#swup-main", "#swup-header", "#swup-footer"],
  plugins: [
    new SwupScrollPlugin(),
    new SwupFadeTheme({ mainElement: "#swup-main" }),
  ],
});

export default swup;
