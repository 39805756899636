import Client from "shopify-buy";
import Alpine from "alpinejs";

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
  domain: "simon-odermatt.myshopify.com", // TODO: ENV
  storefrontAccessToken: "90e272e4609e2dfbed89337e9cd0399c", // TODO: ENV
  apiVersion: "2023-04",
});

// Setup store
Alpine.store("shop", {
  checkoutId: null,
  checkout: null,
  showCart: false,
  toggleCart() {
    this.showCart = !this.showCart;
  },

  async init() {
    // retrieve checkoutId from localStorage
    try {
      const checkoutId = localStorage.getItem("RO-shop-checkoutId");
      if (checkoutId) this.checkoutId = checkoutId;
    } catch (e) {
      console.error(e);
    }

    // If checkout exists, update checkout from Shopify
    if (this.checkoutId) {
      console.log(`Shop: Fetching checkout with id: ${this.checkoutId}`);
      try {
        const fetchedCheckout = await client.checkout.fetch(this.checkoutId);
        this.setCheckout(fetchedCheckout);
      } catch (error) {
        console.error(
          `Shop: Failed to fetch checkout with id: ${this.checkoutId}`,
          error
        );
      }
    }

    // If checkout exists but is already completed, delete it
    if (this.checkout?.completedAt) {
      console.log(`Shop: Checkout already completed. Deleting...`);
      this.checkout = null;
      this.checkoutId = null;
    }

    // If checkout doesn't exist, create a new checkout
    if (!this.checkout) {
      console.log(`Shop: Creating new Checkout...`);
      try {
        const createdCheckout = await client.checkout.create();
        this.setCheckout(createdCheckout);
      } catch (error) {
        console.log(`Shop: Failed to create checkout`, error);
      }
    }
  },

  setCheckout(checkout: Client.Checkout) {
    const checkoutRaw = JSON.parse(JSON.stringify(checkout));
    console.log("Shop: Setting checkout", checkoutRaw);
    this.checkout = checkoutRaw;
    this.checkoutId = checkoutRaw.id;
    // persist checkoutId to localStorage
    try {
      localStorage.setItem("RO-shop-checkoutId", this.checkoutId);
    } catch (error) {
      console.error(error);
    }
  },

  goToCheckout() {
    if (this.checkout) {
      // Redirect to checkout url
      window.location.href = this.checkout.webUrl;
    }
  },

  async addLineItem(variantId: string, quantity: number) {
    try {
      console.log(
        `Shop: Adding item with variantId ${variantId} and quanitity of ${quantity} to checkout`
      );
      const newCheckout = await client.checkout.addLineItems(this.checkoutId, [
        { variantId, quantity },
      ]);
      this.setCheckout(newCheckout);
    } catch (error) {
      console.error(error);
    }
  },

  async removeLineItem(lineItemId: string) {
    try {
      console.log(
        `Shop: Removing line item with id ${lineItemId} from checkout`
      );
      const newCheckout = await client.checkout.removeLineItems(
        this.checkoutId,
        [lineItemId]
      );
      this.setCheckout(newCheckout);
    } catch (error) {
      console.error(error);
    }
  },
});
