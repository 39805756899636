import Alpine from "alpinejs";
import Glide from '@glidejs/glide/dist/glide.esm'

Alpine.directive('glide', (el, { value, modifiers, expression }, { Alpine, effect, cleanup }) => {
  const glide = new Glide(el, {
      autoplay: 10000,
      hoverpause: true,
      rewind: true,
      perView: 1,
      gap: 0,
      breakpoints: {
          640: {
              perView: 1,
          },
      },
  }).mount()

  console.log('glide', glide);

  cleanup(() => {
    glide.destroy()
  });
})
